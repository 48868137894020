import { Button } from '@/components/ui/button';
import { EventName, useAnalytics } from '@packfleet/analytics';
import { useCallback } from 'react';

import { LinkButton } from '@/components/ui/link-button';
import { useSavedState } from '@/hooks/useSavedState';
import { X } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';

const getLocalStorageKey = (id: string) => `broadcast_message_is_visible_${id}`;

type Props = {
  id: string;
  text: string;
  heading: string | null | undefined;
  ctaText: string | null | undefined;
  ctaLink: string | null | undefined;
};

export const BroadcastMessage = ({
  id,
  text,
  heading,
  ctaText,
  ctaLink,
}: Props) => {
  const [show, setShow] = useSavedState(true, getLocalStorageKey(id));
  const analytics = useAnalytics();

  const onClose = useCallback(() => {
    analytics?.track(EventName.dismissedBroadcastMessage, { id });
    setShow(false);
  }, [setShow, analytics, id]);

  const onClick = useCallback(() => {
    analytics?.track(EventName.clickedBroadcastMessage, { id });
  }, [analytics, id]);

  if (!show) {
    return null;
  }

  return (
    <Alert variant="attention" className="mb-4">
      <div className="flex justify-between antialiased gap-2">
        <div className="flex flex-col justify-center">
          {heading ? <AlertTitle>{heading}</AlertTitle> : null}
          <AlertDescription>{text}</AlertDescription>
        </div>
        <div className="flex shrink-0">
          {ctaText && ctaLink ? (
            <LinkButton
              className="self-center"
              href={ctaLink}
              target="_blank"
              size="sm"
              onClick={onClick}
            >
              {ctaText}
            </LinkButton>
          ) : null}
          <Button
            variant="ghost"
            className="w-6 h-6 mt-[-.5rem] translate-x-3 hover:bg-attention-foreground/10"
            aria-label="Close"
            onClick={onClose}
          >
            <X className="size-4" />
          </Button>
        </div>
      </div>
    </Alert>
  );
};
