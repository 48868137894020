import { Coordinates } from '@packfleet/mapper';
import { useEffect, useMemo, useRef, useState } from 'react';

export function useAnimatedRoutePolyline(routePolyline: Coordinates[] | null) {
  const [stateCounter, setStateCounter] = useState(1);
  const animationFrame = useRef<number>(1);
  const previousTime = useRef<number | null>(null);

  const animator = useRef<number | undefined>();
  const animate = (time: number) => {
    if (animationFrame.current >= (routePolyline?.length ?? 0)) {
      return;
    }
    if (!previousTime.current || time - previousTime.current >= 16) {
      // Enforce at most 30fps
      setStateCounter((val) => {
        animationFrame.current = val + 1;
        return animationFrame.current;
      });
      previousTime.current = time;
    }

    animator.current = requestAnimationFrame(animate);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: animate
  useEffect(() => {
    if (!routePolyline) return;

    setTimeout(() => {
      animator.current = requestAnimationFrame(animate);
      return () =>
        animator.current != null
          ? cancelAnimationFrame(animator.current)
          : null;
    }, 1500);
  }, [routePolyline]);
  return useMemo(
    () => routePolyline?.slice(0, stateCounter) ?? [],
    [routePolyline, stateCounter],
  );
}
