import cs from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

export type Props = {
  bgColor: string;
  textColor: string;
  opacity?: number;
  icon: ReactNode;
  className?: string;
};

const CircleMarker: FunctionComponent<Props> = ({
  bgColor,
  textColor,
  icon,
  opacity = 1,
  className,
}) => {
  return (
    <div className="relative">
      <div
        className={cs(
          'absolute z-10 flex h-10 w-10 items-center justify-center rounded-full text-xl',
          className,
        )}
        style={{
          borderColor: textColor,
          backgroundColor: bgColor,
          borderWidth: 3,
          color: textColor,
          left: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
          transition: 'opacity 150ms ease-out',
          opacity,
        }}
      >
        {icon}
      </div>
    </div>
  );
};

export default CircleMarker;
