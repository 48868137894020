import { BroadcastMessages } from '@/components/broadcast-messages/BroadcastMessages';
import { SectionButton } from '@/components/button/SectionButton';
import { Icons } from '@/components/navbar/Icons';
import {
  SHIPMENT_GROUPINGS,
  ShipmentGrouping,
} from '@/components/shipments/shipment-groupings';
import { CardDescription } from '@/components/ui/card';
import { LinkButton } from '@/components/ui/link-button';
import { SidebarTrigger } from '@/components/ui/sidebar';
import { groupBy } from '@/utilities/lib';
import { pluralize } from '@/utilities/pluralize';
import { ImportableShipmentsBanner } from '@components/inbox/ImportableShipmentsBanner';
import { formatFriendlyTimezone } from '@packfleet/datetime';
import { Plus, Repeat } from 'lucide-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {} from 'react-icons/io5';
import slugify from 'slugify';
import {
  ClaimFragment,
  CollectionFragment,
  CollectionLiveTrackingFragment,
  CollectionLocationFragment,
  ShipmentFragment,
} from '../../../generated/graphql';
import {
  useDeviceTimezone,
  useOrganizationTimezone,
} from '../../../hooks/timezone';
import {
  useCurrentUser,
  useIsBrandUser,
  useUserRole,
} from '../../../hooks/useCurrentUser';
import { Routes, route } from '../../../utilities/routes';
import Banner from '../../banner/Banner';
import { CollectionLocationsSelector } from '../../collections/CollectionLocationsSelector';
import CollectionsCalendar from '../../collections/CollectionsCalendar';
import Container from '../../container/Container';
import Heading from '../../heading/Heading';
import Page from '../../page/Page';
import Sidebar from '../../sidebar/Sidebar';
type Props = {
  locationId?: string;
  shipments: ShipmentFragment[];
  hasAnyShipments: boolean;
  collections: CollectionFragment[];
  collectionLocations: CollectionLocationFragment[];
  collectionTrackingInfos: CollectionLiveTrackingFragment[];
  claims: ClaimFragment[];
};

function DashboardPage({
  locationId,
  shipments,
  collections,
  collectionLocations,
  collectionTrackingInfos,
  hasAnyShipments,
  claims,
}: Props) {
  const router = useRouter();
  const user = useCurrentUser();
  const { isBrandUser } = useIsBrandUser();
  const { hasWriteRole } = useUserRole();
  const orgTimezone = useOrganizationTimezone();
  const deviceTimezone = useDeviceTimezone();
  const [selectedLocation, setSelectedLocation] =
    useState<CollectionLocationFragment | null>(null);

  const filteredShipments = shipments.filter(
    (s) =>
      selectedLocation == null ||
      selectedLocation.id === s.collectionLocation.id,
  );

  const shipmentsByGrouping = groupBy(filteredShipments, (i) => {
    return (
      Object.keys(SHIPMENT_GROUPINGS).find((k) => {
        return SHIPMENT_GROUPINGS[k as ShipmentGrouping].includes(i.status);
      }) ?? ''
    );
  });

  const filteredCollections = collections.filter(
    (s) => selectedLocation == null || selectedLocation.id === s.location.id,
  );

  const hasMultipleLocations =
    collectionLocations && collectionLocations.length > 1;

  useEffect(() => {
    setSelectedLocation(
      collectionLocations.find((l) => locationId === l.id) ?? null,
    );
  }, [collectionLocations, locationId]);

  const handleSelectedLocationsChange = async (
    selectedLocation: CollectionLocationFragment | null,
  ) => {
    if (selectedLocation != null) {
      setSelectedLocation(selectedLocation);

      const slug = slugify(
        selectedLocation.name ??
          `${selectedLocation.address.line1}-${selectedLocation.address.postCode}`,
        { lower: true },
      );

      await router.push(
        route(Routes.appLocationDashboard, {
          id: selectedLocation.id,
          slug: slug,
        }),
      );
    } else {
      setSelectedLocation(null);
      await router.push(route(Routes.appDashboard));
    }
  };

  if (!user || !user.organization) {
    return null;
  }

  return (
    <Page
      title="Packfleet"
      fullHeight={true}
      sidebar={<Sidebar activeTitle="Home" />}
      intercomEnabled={true}
    >
      <Container>
        <div className="flex gap-2 items-center pb-2 -mt-1">
          <SidebarTrigger className="-ml-1.5" />
          <h2 className="mb-3 flex-1 md:mb-0 text-sm font-medium">Dashboard</h2>
        </div>
        <BroadcastMessages />
        <ImportableShipmentsBanner />
        <div className="relative">
          <a id="collections" className="absolute -top-20 md:-top-4" />
          {orgTimezone !== deviceTimezone ? (
            <div className="mb-4 flex items-center justify-center rounded bg-warningLight p-2 text-center text-warning">
              <IoIosInformationCircleOutline className="mr-2 text-2xl" />
              <span>
                {deviceTimezone
                  ? `It looks like youʼre in ${formatFriendlyTimezone(
                      deviceTimezone,
                    )}.`
                  : null}
                Your Packfleet dashboard will still appear in{' '}
                {formatFriendlyTimezone(orgTimezone)}
              </span>
            </div>
          ) : null}
          {claims.length > 0 && hasWriteRole ? (
            <Banner
              title="One of your claims requires more information"
              ctaText="Go to claims"
              href={Routes.appShipmentsDelivered}
              variant="attention"
            />
          ) : null}
          <Heading level={2} headingStyle="heading1">
            Collections
          </Heading>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex sm:flex-1 sm:items-center">
              {!isBrandUser && hasWriteRole && (
                <>
                  <LinkButton
                    variant="outline"
                    className="mt-3 w-full sm:mr-2 sm:w-auto"
                    href={route(Routes.appNewCollection)}
                  >
                    <Plus />
                    Book a collection
                  </LinkButton>
                  {user.organization.returnCollectionsAllowed ? (
                    <LinkButton
                      variant="outline"
                      size="sm"
                      className="mt-3 w-full sm:mr-2 sm:w-auto"
                      href={route(Routes.appNewReturn)}
                    >
                      <Plus />
                      Book a return
                    </LinkButton>
                  ) : null}
                  <LinkButton
                    variant="outline"
                    className="mt-3 w-full sm:mr-2 sm:w-auto"
                    href={route(Routes.appManageRecurringCollection)}
                  >
                    <Repeat />
                    Manage recurring collections
                  </LinkButton>
                </>
              )}
            </div>
            <div className="sm:justify mt-3 w-full sm:w-auto md:flex">
              {!isBrandUser && hasWriteRole && (
                <div>
                  <LinkButton
                    variant="outline"
                    color="brand"
                    className="mb-3 w-full sm:mr-2 sm:w-auto md:ml-3 md:mb-0"
                    href={route(Routes.appManageCollectionLocations)}
                  >
                    Manage locations
                  </LinkButton>
                </div>
              )}
              {hasMultipleLocations && (
                <CollectionLocationsSelector
                  locations={collectionLocations}
                  selectedLocation={selectedLocation}
                  onChange={handleSelectedLocationsChange}
                />
              )}
            </div>
          </div>
          {collections.length > 0 ? (
            <div className="mt-8 w-full">
              <CollectionsCalendar
                organization={user.organization}
                collections={filteredCollections}
                liveTracking={collectionTrackingInfos}
              />
            </div>
          ) : null}
        </div>
        <div className="relative mt-16">
          <div>
            <>
              <a id="shipments" className="absolute -top-20 md:-top-4" />
              <Heading level={2} headingStyle="heading1" className="mb-3">
                Shipments
              </Heading>
              <div className="sm:flex sm:items-center mb-16">
                <div className="flex flex-wrap gap-4 sm:flex-1 sm:items-center">
                  {hasAnyShipments ? (
                    <>
                      {hasWriteRole && (
                        <SectionButton
                          href={route(Routes.appShipments)}
                          icon={Icons.add}
                          title="Add shipments"
                          description="Manually, or via CSV, Shopify, etc."
                        />
                      )}
                      {Object.keys(SHIPMENT_GROUPINGS).map((grouping) => {
                        let icon;
                        let title = grouping;
                        let href = '';

                        switch (grouping as ShipmentGrouping) {
                          case ShipmentGrouping.toCollect:
                            icon = Icons.collectionsInverted;
                            title = 'Ready to collect and print labels';
                            href = route(Routes.appShipmentsToCollect);
                            break;
                          case ShipmentGrouping.onTheirWay:
                            icon = Icons.onTheirWayInverted;
                            href = route(Routes.appShipmentsOnTheirWay);
                            break;
                          case ShipmentGrouping.delivered:
                            icon = Icons.deliveredInverted;
                            href = route(Routes.appShipmentsDelivered);
                            break;
                        }

                        // Exclude "failed" for now
                        if (grouping === ShipmentGrouping.failed) {
                          return null;
                        }

                        const numShipments =
                          shipmentsByGrouping[grouping]?.length ?? 0;

                        return (
                          <SectionButton
                            key={href}
                            href={href}
                            icon={icon}
                            title={title}
                            description={
                              numShipments > 0
                                ? numShipments +
                                  ' ' +
                                  pluralize(
                                    numShipments,
                                    'shipment',
                                    'shipments',
                                  )
                                : 'No shipments yet'
                            }
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div className="mb-4 bg-muted rounded-lg py-32 w-full">
                      <div className="w-100 flex flex-col items-center justify-center p-4">
                        <CardDescription className="mb-3">
                          Ready to send your first shipment?
                        </CardDescription>
                        <LinkButton href={route(Routes.appShipments)}>
                          Add shipments
                        </LinkButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </Container>
    </Page>
  );
}
export default DashboardPage;
