import {
  BroadcastMessageScope,
  useListBroadcastMessagesQuery,
} from '@/generated/graphql';
import { ReactNode } from 'react';
import { BroadcastMessage } from './BroadcastMessage';

type Props = {
  fallback?: ReactNode;
};

export const BroadcastMessages = ({ fallback }: Props) => {
  const { data, loading } = useListBroadcastMessagesQuery({
    variables: { input: { scope: BroadcastMessageScope.Merchant } },
  });

  const messages = data?.listBroadcastMessages?.messages ?? [];

  if (!loading && messages.length === 0) {
    return <>{fallback}</>;
  }

  return (
    <>
      {messages.map(({ id, text, heading, ctaText, ctaLink }) => (
        <BroadcastMessage
          key={id}
          id={id}
          text={text}
          heading={heading}
          ctaText={ctaText}
          ctaLink={ctaLink}
        />
      ))}
    </>
  );
};
