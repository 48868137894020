import { LinkButton } from '@/components/ui/link-button';
import { pluralize } from '@/utilities/pluralize';
import { Routes, route } from '@/utilities/routes';
import { Alert, AlertTitle } from '../ui/alert';
import { useCountImportableShipments } from './useCountImportableShipments';

export const ImportableShipmentsBanner = () => {
  const { totalImportableShipments, importableShipmentsIsPaginated } =
    useCountImportableShipments();

  if (!totalImportableShipments) {
    return null;
  }

  return (
    <Alert className="mb-4" variant="attention">
      <div className="flex w-full items-center justify-between">
        <AlertTitle className="mb-0">
          You have {importableShipmentsIsPaginated ? 'over ' : undefined}
          {totalImportableShipments}{' '}
          {pluralize(totalImportableShipments, 'shipment', 'shipments')} ready
          to be imported
        </AlertTitle>
        <LinkButton
          size="sm"
          href={route(Routes.appShipments)}
          className="w-full md:w-auto"
          variant="attention"
        >
          View shipments
        </LinkButton>
      </div>
    </Alert>
  );
};
