import {
  isDateAfter,
  isDateBefore,
  isToday,
  parsePlainDate,
  todayLocal,
} from '@packfleet/datetime';
import { CollectionFragment } from '../generated/graphql';
import { formatCollectionLocation } from './collection-locations';
import { formatFriendlyDate } from './date';
import { formatStartEndTime } from './time';

export function formatCollection(
  cl: CollectionFragment,
  timezone: string,
  showLocation?: boolean,
  showTime?: boolean,
) {
  const date = parsePlainDate(cl.date);
  const location = formatCollectionLocation(cl.location);

  let locationText = '';
  if (showLocation) {
    locationText = ` from ${location}`;
  }
  let timeText = '';
  if (showTime) {
    timeText = ` ${formatStartEndTime(cl.startTime, cl.endTime)}`;
  }
  const dateStr = formatFriendlyDate(date, timezone);

  return `${dateStr}${locationText}${timeText}`;
}

export function isPastCollection(coll: CollectionFragment, timezone: string) {
  const d = parsePlainDate(coll.date);
  return isDateBefore(d, todayLocal(timezone));
}

export function isNextCollection(coll: CollectionFragment, timezone: string) {
  const d = parsePlainDate(coll.date);
  return isToday(d, timezone);
}

export function isFutureCollection(coll: CollectionFragment, timezone: string) {
  const d = parsePlainDate(coll.date);
  return isDateAfter(d, todayLocal(timezone));
}
