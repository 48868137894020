import React, { ReactNode } from 'react';
import { LinkButton } from '../ui/link-button';

export const SectionButton = ({
  href,
  icon,
  title,
  description,
}: {
  href: string;
  icon: ReactNode;
  title: string;
  description: string;
}) => (
  <LinkButton
    href={href}
    variant="outline"
    size="lg"
    className="w-full md:w-auto md:min-w-[300px] no-underline px-4 h-16 justify-start flex"
  >
    <div className="overflow-hidden rounded [&_svg]:size-8">{icon}</div>
    <div>
      {title}
      <div className="text-muted-foreground">{description}</div>
    </div>
  </LinkButton>
);
