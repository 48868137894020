import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import css from './Tooltip.module.css';

export type Props = {
  title: string;
  children?: ReactNode;
};

const Tooltip = ({ title, children }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);
  const child = React.Children.only(children);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!childRef.current) return;
      if (childRef.current.contains(e.target as Node)) {
        show();
      } else {
        hide();
      }
    };
    window.addEventListener('mousemove', listener);
    return () => {
      window.removeEventListener('mousemove', listener);
    };
  }, []);

  const hide = useCallback(() => {
    if (!ref.current || !innerRef.current) return;
    ref.current.style.visibility = 'hidden';
    innerRef.current.classList.remove(css.tooltipEntry);
  }, []);

  const show = useCallback(() => {
    if (!childRef.current || !ref.current || !innerRef.current) return;
    const childRect = childRef.current.getBoundingClientRect();
    const rect = ref.current.getBoundingClientRect();

    const top = childRect.top - rect.height - 10 + window.scrollY;
    const left = childRect.left + childRect.width / 2 - rect.width / 2;
    ref.current.style.transform = `translate3d(${left}px, ${top}px, 0)`;

    ref.current.style.visibility = 'visible';
    innerRef.current.classList.add(css.tooltipEntry);
  }, []);

  const arrowSize = 10;
  const tooltipColor = 'rgb(66,75,91)';

  return (
    <>
      <div ref={childRef} key="tooltipBase">
        {child}
      </div>
      {ReactDOM.createPortal(
        <div
          ref={ref}
          style={{
            zIndex: 99999,
            visibility: 'hidden',
            top: 0,
            left: 0,
          }}
          className="absolute"
        >
          <div
            ref={innerRef}
            className="relative rounded p-2 text-sm text-primaryInverted"
            style={{
              backgroundColor: tooltipColor,
              lineHeight: '100%',
            }}
          >
            {title}
            <div
              className="absolute"
              style={{
                width: arrowSize,
                height: arrowSize,
                transform: 'rotate(45deg)',
                backgroundColor: tooltipColor,
                bottom: -arrowSize / 2,
                left: '50%',
                marginLeft: -arrowSize / 2,
              }}
            />
          </div>
        </div>,
        document.body,
        'tooltip',
      )}
    </>
  );
};

export default Tooltip;
