import * as polyline from '@mapbox/polyline';
import {
  MapWrapper,
  ReactGoogleMap,
  ReactMarker,
  ReactPolyline,
} from '@packfleet/mapper';
import { Colors } from '@packfleet/ui';
import flatten from 'lodash.flatten';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { LiveTrackingInfo } from '../../generated/graphql';
import { useAnimatedRoutePolyline } from '../../hooks/useAnimatedRoutePolyline';
import { MAP_API_KEY, MERCHANT_MAP_ID } from '../../utilities/constants';
import CircleMarker from './CircleMarker';

export type Props = {
  senderEmoji?: string | null;
  liveInfo: LiveTrackingInfo;
};

const CollectionTrackingMap: FunctionComponent<Props> = ({
  liveInfo,
  senderEmoji,
}) => {
  const mapRef = useRef<MapWrapper | null>(null);
  const zoom = useRef(14);
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary: liveInfo
  useEffect(() => {
    if (mapRef.current && mapRef.current.getZoom() < 17) {
      mapRef.current?.fitContents(40);
    } else {
      mapRef.current?.setZoom(17);
    }
  }, [liveInfo]);

  const onInit = useCallback(() => {
    setTimeout(() => {
      mapRef.current?.fitContents(40);
      if (mapRef.current && mapRef.current.getZoom() > 17) {
        mapRef.current?.setZoom(17);
      }
    }, 500);
  }, []);

  const driverCoords = liveInfo.driverLocation;
  const routePolyline = useMemo(
    () =>
      liveInfo.driverRoutePolyline
        ? polyline.decode(liveInfo.driverRoutePolyline).map((c) => ({
            lat: c[0],
            lng: c[1],
          }))
        : null,
    [liveInfo],
  );

  const onZoomChanged = useCallback(() => {
    if (!mapRef.current) return;
    const mapZoom = Math.round(mapRef.current.getZoom());
    if (mapZoom !== zoom.current) {
      zoom.current = mapZoom;
    }
  }, []);

  const animatedRoutePolyline = useAnimatedRoutePolyline(routePolyline);

  return (
    <ReactGoogleMap
      mapRef={mapRef}
      onInit={onInit}
      hideZoomControl={true}
      onZoomChanged={onZoomChanged}
      disableMapDragging={true}
      disableDoubleClickZoom={true}
      disableScrollToZoom={true}
      simplifyPolylines={false}
      apiKey={MAP_API_KEY}
      items={flatten([
        routePolyline ? (
          <ReactPolyline
            key="route-polyline-invisible"
            zIndex={0}
            path={routePolyline}
            strokeColor="hsl(var(--attention-background))"
            strokeWidth={12}
            strokeOpacity={0.01}
          />
        ) : null,
        animatedRoutePolyline ? (
          <ReactPolyline
            key="route-polyline"
            zIndex={1}
            path={animatedRoutePolyline}
            strokeColor="#4949F1"
            strokeWidth={3}
            strokeOpacity={1}
          />
        ) : null,

        liveInfo.destinationLocation ? (
          <ReactMarker
            key={'destination-location'}
            coords={{
              lat: liveInfo.destinationLocation.latitude,
              lng: liveInfo.destinationLocation.longitude,
            }}
          >
            <CircleMarker
              icon={senderEmoji ?? '🏢'}
              bgColor={Colors.white}
              textColor="hsl(var(--attention-foreground))"
            />
          </ReactMarker>
        ) : null,
        driverCoords ? (
          <ReactMarker
            key={'driver-location'}
            coords={{
              lat: driverCoords.latitude,
              lng: driverCoords.longitude,
            }}
          >
            <CircleMarker
              icon={'🚛'}
              bgColor={Colors.white}
              textColor="hsl(var(--attention-foreground))"
            />
          </ReactMarker>
        ) : null,
      ])}
      className="h-full w-full"
      initialCenter={
        liveInfo.destinationLocation
          ? {
              lat: liveInfo.destinationLocation.latitude,
              lng: liveInfo.destinationLocation?.longitude,
            }
          : { lat: 0, lng: 0 }
      }
      initialZoom={14}
      mapId={MERCHANT_MAP_ID}
    />
  );
};

export default CollectionTrackingMap;
