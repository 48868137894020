import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { LinkButton } from '@/components/ui/link-button';
import React, { ReactNode } from 'react';
import { TbX } from 'react-icons/tb';

type Variant = 'default' | 'destructive' | 'attention';
type Props = {
  title?: string;
  description?: string;
  ctaText?: string;
  onDismiss?: () => Promise<void>;
  variant: Variant;
  icon?: ReactNode;
  isDismissing?: boolean;
} & (
  | {
      onClick?: () => Promise<void>;
      href?: never;
    }
  | {
      href?: string;
      onClick?: never;
    }
);

const Banner = ({
  title,
  description,
  ctaText,
  onClick,
  href,
  onDismiss,
  variant,
  icon,
  isDismissing,
}: Props) => {
  const ctaButton = !!onClick ? (
    <Button
      variant="outline"
      size="sm"
      onClick={onClick}
      className="-translate-y-0.5"
    >
      {ctaText}
    </Button>
  ) : !!href ? (
    <LinkButton href={href} variant="outline" size="sm">
      {ctaText}
    </LinkButton>
  ) : null;

  return (
    <Alert variant={variant} className="mb-3">
      <div className="flex justify-between gap-2 items-center">
        <span className="shrink-0">{icon}</span>
        <div className="w-full">
          {title && <AlertTitle>{title}</AlertTitle>}
          <AlertDescription>
            {description && <p>{description}</p>}
          </AlertDescription>
        </div>
        <div className="sm:w-auto sm:block w-full flex justify-end flex-shrink-0">
          {ctaButton}
          {onDismiss && (
            <Button
              variant="ghost"
              size="icon"
              onClick={onDismiss}
              disabled={isDismissing}
              className="text-muted-foreground"
            >
              {isDismissing ? (
                <svg
                  className="h-4 w-4 animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <TbX className="h-4 w-4" />
              )}
            </Button>
          )}
        </div>
      </div>
    </Alert>
  );
};

export default Banner;
