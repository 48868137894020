import { ProofOfCompletionStatus } from '../generated/graphql';

export function formatProofOfCompletionStatus(status: ProofOfCompletionStatus) {
  switch (status) {
    case ProofOfCompletionStatus.Collected:
      return 'Collected';
    case ProofOfCompletionStatus.DeliveredToRecipient:
      return 'Delivered to recipient';
    case ProofOfCompletionStatus.LeftInSafePlace:
      return 'Left in safe place';
    case ProofOfCompletionStatus.LeftWithNeighbour:
      return 'Left with neighbour';
    case ProofOfCompletionStatus.NobodyHomeReattemptLater:
      return "Nobody home, we'll try again next business day";
    case ProofOfCompletionStatus.NothingToCollect:
      return 'We couldnʼt collect – there was nothing to collect';
    case ProofOfCompletionStatus.UnableToCollectSeeNotes:
      return 'We couldnʼt collect: ';
    case ProofOfCompletionStatus.UnableToDeliverReattemptLater:
      return "We couldnʼt deliver, we'll try again next business day";
    case ProofOfCompletionStatus.UnableToDeliverSeeNotes:
      return 'We couldnʼt deliver, see notes for more information';
  }
}
